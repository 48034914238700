const subscriptionPlans = [
  {
    id: -1,
    description: 'Basic plan for those who are just checking us out',
    branda_type: 'Trial',
    price: '$0',
    subtitle: '',
    list_items: '<b>20 FREE</b> Post Credits \n Discover your Brand Identity \n Restricted Premium features',
    product_id: -1,
    paypro_url: '/subscription?trial=true',
    cta_label: 'Purchase',
    cta_color: '#000000',
    cta_font_color: '#ffffff'
  },
  {
    id: 3,
    description: 'Professional plan to skyrocket your online presence!',
    branda_type: 'Pro',
    price: '$14.99',
    subtitle: 'Pay as you go',
    list_items: '<b>200</b> Post Credits \n Discover your Brand Identity \n Personalized Branding Plan <span class="coming-soon">Coming Soon</span>',
    paypro_url: 'https://store.payproglobal.com/checkout?products[1][id]=104921',
    product_id: 104921,
    cta_label: 'Purchase',
    cta_color: '#000000',
    cta_font_color: '#ffffff'
  },
  {
    id: 2,
    description: 'Best value for entrepreneurs and business owners',
    branda_type: 'Premium',
    price: '$29.99',
    subtitle: 'Pay as you go',
    list_items: '<b>500</b> Post Credits \n Discover your Brand Identity \n Personalized Branding Plan <span class="coming-soon">Coming Soon</span>',
    paypro_url: 'https://store.payproglobal.com/checkout?products[1][id]=104922',
    product_id: 104922,
    cta_label: 'Purchase',
    cta_color: '#F9D783',
    cta_font_color: '#3D4483'
  },
  {
    id: 4,
    description: 'Contact us for SMB’s and Enterprise custom plans',
    branda_type: 'Custom',
    price: 'Contact Us',
    subtitle: '',
    list_items: 'Custom-made branding plans \n Thought Leadership programs \n Become an affiliate',
    paypro_url: '/contact-us?message=Tell me more about custom business plans!',
    product_id: 0,
    cta_label: 'Book A Demo',
    cta_color: '#000000',
    cta_font_color: '#ffffff',
    is_disabled: true
  }
]

export default subscriptionPlans
