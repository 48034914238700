import BrandReportForm from './BrandReportForm'
import BrandReportShort from './BrandReportShort'
import { useParams } from 'react-router-dom'
export default function BrandReport () {
  const { id } = useParams()

  return (
    <>
    {/* Metadata for this page comes from /public/mybrandid/index.html */}
      <div className='px-4 quiz_email_page visible bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-12 mb-10 md:mb-28'>
        {id === undefined && <BrandReportForm />}
        {id !== undefined && <BrandReportShort />}
      </div>
    </>
  )
}
